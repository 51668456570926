<template>
  <div class="bigDriverManage">
    <div class="facilityBox">
      <input
        type="file"
        style="display: none"
        @change="handleUploadList"
        ref="uploadList"
      />
      <el-form
        class="searchForm"
        :model="searchForm"
        ref="searchForm"
        label-width="110px"
      >
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item
            label="司机姓名："
            prop="driverName"
          >
            <el-input
              v-model="searchForm.driverName"
              placeholder="请输入司机姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="司机手机号："
            prop="telPhone"
          >
            <el-input
              v-model="searchForm.telPhone"
              placeholder="请输入司机手机"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="司机身份证号："
            prop="driverIDCard"
            label-width="120px"
          >
            <el-input
              v-model="searchForm.driverIDCard"
              placeholder="请输入司机身份证号"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="服务商名称："
            prop="supplerName"
            v-if="roleType == 'platform'"
          >
            <el-input
              v-model="searchForm.supplerName"
              placeholder="服务商名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item label-width="10px">
            <div style="white-space: nowrap">
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                @click="search"
                icon="el-icon-search"
              >搜索</el-button>
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                icon="el-icon-delete"
                @click="resetForm"
              >清空</el-button>
              <el-button
                type="primary"
                size="medium"
                @click="toGetTemplate"
                icon="el-icon-download"
                v-if="roleType != 'platform'"
              >
                下载模板
              </el-button>
              <el-button
                type="primary"
                size="medium"
                @click="$refs.uploadList.click()"
                icon="el-icon-upload2"
                v-if="roleType != 'platform'"
              >
                导入司机表格
              </el-button>
              <el-button
                type="danger"
                size="medium"
                @click="delItem"
                icon="el-icon-delete"
                v-if="roleType != 'platform'"
                :disabled="!multipleSelection.length"
              >
                勾选删除司机
              </el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <ux-grid
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        style="width: 100%"
        height="600"
        @select="handleSelectionChange"
        @select-all="handleSelectionChange"
        show-overflow
        :border="false"
        :row-style="{height:'55px'}"
        ref="uxGridRef"
      >
        <ux-table-column
          type="checkbox"
          align="center"
          width="50"
          fixed="left"
          v-if="roleType != 'platform'"
        ></ux-table-column>
        <ux-table-column
          type="index"
          align="center"
          title="序号"
          width="50"
          fixed="left"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="SupplerName"
          title="服务商名称"
          width="200"
          fixed="left"
          show-overflow
          v-if="roleType == 'platform'"
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="DriverName"
          title="姓名"
          width="200"
          fixed="left"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="TelPhone"
          title="手机号"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="DriverIDCard"
          title="身份证号"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="VehicleCarNumber"
          title="车牌号"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="DriverLicenseType"
          title="驾驶证类型"
          width="200"
          show-overflow
        ></ux-table-column>

        <ux-table-column
          align="center"
          field="DriverLicenseDateStart"
          title="驾驶证起始日期"
          width="200"
          show-overflow
        ></ux-table-column>

        <ux-table-column
          align="center"
          field="DriverLicenseDateEnd"
          title="驾驶证有效期"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="DriverLicesenAuthority"
          title="驾驶证发证机关"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="BankCardNumber"
          title="银行卡号"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="Address"
          title="地址"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          fixed="right"
          title="操作"
          width="180"
          show-overflow
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="toEditDriver(scope.row)"
              v-if="parentRoleId != 1"
            >编辑</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="watchDetail(scope.row)"
            >详情</el-button>
          </template>
        </ux-table-column>
      </ux-grid>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        :page-sizes="[10, 50, 100, 500, 1000]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <!-- 查看详情 -->
    <el-dialog
      width="1500px"
      title="司机详情"
      center
      :visible.sync="detailDialog"
      append-to-body
      v-if="detailDialog"
      :close-on-click-modal="false"
      class="detail-dialog"
    >
      <div class="content">
        <div
          class="upBox"
          v-if="detailInfo.DriverCardOnURL"
        >
          <el-image
            class="img"
            :src="detailInfo.DriverCardOnURL"
            :preview-src-list="srcList"
          ></el-image>
          <div class="upText">身份证(正面)</div>
        </div>
        <div
          class="upBox"
          v-if="detailInfo.DriverCardBackURL"
        >
          <el-image
            class="img"
            :src="detailInfo.DriverCardBackURL"
            :preview-src-list="srcList"
          ></el-image>
          <div class="upText">身份证(反面)</div>
        </div>
        <div
          class="upBox"
          v-if="detailInfo.DriverLicenseOnURL"
        >
          <el-image
            class="img"
            :src="detailInfo.DriverLicenseOnURL"
            :preview-src-list="srcList"
          ></el-image>
          <div class="upText">驾驶证(正页)</div>
        </div>
        <div
          class="upBox"
          v-if="detailInfo.DriverLicenseBackURL"
        >
          <el-image
            class="img"
            :src="detailInfo.DriverLicenseBackURL"
            :preview-src-list="srcList"
          ></el-image>
          <div class="upText">驾驶证(副页)</div>
        </div>
        <div
          class="upBox"
          v-if="detailInfo.RTQCertificateURL"
        >
          <el-image
            class="img"
            :src="detailInfo.RTQCertificateURL"
            :preview-src-list="srcList"
            fit="contain"
          ></el-image>
          <div class="upText">道路运输从业资格证</div>
        </div>
      </div>
      <el-form
        :model="detailInfo"
        label-width="160px"
        disabled
      >
        <div
          class="title"
          style="font-weight: bold;font-size: 16px;margin: 10px;"
        >基本资料</div>
        <div
          class="content"
          style="justify-content: left"
        >
          <el-form-item label="人员姓名">
            <el-input v-model="detailInfo.DriverName"></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input v-model="detailInfo.DriverIDCard"></el-input>
          </el-form-item>
          <!-- <el-form-item label="身份证有效期">
            <el-input v-model="detailInfo.DriverIDCardEndDate"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="身份证起始日期">
            <el-input v-model="detailInfo.DriverIDCardStartDate"></el-input>
          </el-form-item> -->
          <el-form-item label="人员手机号">
            <el-input v-model="detailInfo.TelPhone"></el-input>
          </el-form-item>
          <el-form-item label="准驾类型">
            <el-input v-model="detailInfo.DriverLicenseType"></el-input>
          </el-form-item>
          <el-form-item label="驾驶证起始日期">
            <el-input v-model="detailInfo.DriverLicenseDateStart"></el-input>
          </el-form-item>
          <el-form-item label="驾驶证有效期">
            <el-input v-model="detailInfo.DriverLicenseDateEnd"></el-input>
          </el-form-item>
          <!-- <el-form-item label="性别">
            <el-input v-model="detailInfo.DriverSex"></el-input>
          </el-form-item>
          <el-form-item label="驾驶证档案编号">
            <el-input v-model="detailInfo.DriverLicenseArchives"></el-input>
          </el-form-item> -->
          <el-form-item label="驾驶证发证机关">
            <el-tooltip
              class="item"
              effect="dark"
              :content="detailInfo.DriverLicesenAuthority"
              placement="top-start"
            >
              <el-input v-model="detailInfo.DriverLicesenAuthority"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="从业资格证号">
            <el-input v-model="detailInfo.RTQCertificate"></el-input>
          </el-form-item>
          <!-- <el-form-item label="从业资格证有效期">
            <el-input v-model="detailInfo.RTQCertificateDate"></el-input>
          </el-form-item> -->
          <el-form-item label="地址">
            <el-tooltip
              class="item"
              effect="dark"
              :content="detailInfo.Address"
              placement="top-start"
            >
              <el-input v-model="detailInfo.Address"></el-input>
            </el-tooltip>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  DriverList,
  ImportDriver,
  DriverDel,
  DriverDetail,
  getTemplate
} from "@/api/bigCustomerManage/index";
import { _getParentRoleId } from "@/utils/storage";

export default {
  props: {
    roleType: { // 角色类型
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      parentRoleId: null,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索表单
      searchForm: {
        driverIDCard: '',
        driverName: '',
        telPhone: '',
        supplerName: ''
      },
      tableData: [], //表格数据
      loading: false,
      detailDialog: false,
      detailInfo: {},
      srcList: [],
      multipleSelection: []
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    watchDetail(item) {
      this.loading = true;
      DriverDetail({
        userID: item.UserID,
        driverIDCard: item.DriverIDCard,
      })
        .then((res) => {
          this.detailInfo = res.data || {}
          if (this.detailInfo.DriverCardOnURL) {
            this.srcList.push(this.detailInfo.DriverCardOnURL)
          }
          if (this.detailInfo.DriverCardBackURL) {
            this.srcList.push(this.detailInfo.DriverCardBackURL)
          }
          if (this.detailInfo.DriverLicenseOnURL) {
            this.srcList.push(this.detailInfo.DriverLicenseOnURL)
          }
          if (this.detailInfo.DriverLicenseBackURL) {
            this.srcList.push(this.detailInfo.DriverLicenseBackURL)
          }
          if (this.detailInfo.RTQCertificateURL) {
            this.srcList.push(this.detailInfo.RTQCertificateURL)
          }
          this.detailDialog = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 删除
    delItem() {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.toDelFn(this.multipleSelection)
        })
        .catch(() => { });
    },

    toDelFn(arr) {
      if (!arr.length) return;

      this.loading = true
      let len = arr.length; // 请求总数
      let sendCount = 0; // 已发送的请求数量
      let errorCount = 0; // 错误请求
      const _this = this;

      return new Promise((resolve, reject) => {
        next();

        function next() {
          let current = sendCount++; // 当前发送的请求数量，后加一 保存当前请求url的位置
          if (arr[current]) {
            DriverDel({
              userID: arr[current].UserID,
              driverIDCard: arr[current].DriverIDCard,
            }).then(() => {
            }).catch(() => {
              errorCount = errorCount + 1;
            }).finally(() => {
              if (current < len - 1) { // 如果请求没有发送完，继续发送请求
                next();
              } else if (current == len - 1) {
                _this.loading = false
                _this.search();
                _this.multipleSelection = []
                _this.$refs.uxGridRef.clearSelection()

                if (errorCount) {
                  _this.$message.warning(`存在${errorCount}条，删除异常`)
                } else {
                  _this.$message.success("删除成功");
                }
                resolve();
                return;
              }
            })
          }
        }
      })
    },

    //上传
    handleUploadList(e) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let formData = new FormData()
      formData.append('file', e.srcElement.files[0] || e.target.files[0])
      ImportDriver(formData)
        .then((res) => {
          this.$message.success("导入成功")
          this.getDriverList()
        })
        .finally(() => {
          this.$refs.uploadList.value = null
          loading.close()
        })
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.loading = true;
      this.getDriverList();
    },
    //清空
    resetForm() {
      this.loading = true;
      this.searchForm = {
        driverIDCard: '',
        driverName: '',
        telPhone: '',
        supplerName: ''
      };
      this.pagination.page = 1;
      this.getDriverList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getDriverList();
    },
    sizeChange(e) {
      this.loading = true;
      this.pagination.pagesize = e;
      this.getDriverList();
    },
    //获取列表
    getDriverList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        isPlat: this.roleType == 'platform' ? true : false,
        ...this.searchForm
      };
      this.loading = true;
      DriverList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    toEditDriver(item) {
      this.$router.push({
        path: "/bigCustomerManage/bigDriverManage/editDriver",
        query: {
          pagination: { ...this.pagination },
          searchForm: { ...this.searchForm },
          userId: item.UserID,
          driverIDCard: item.DriverIDCard,
        },
      });
    },

    // 下载模板
    async toGetTemplate() {
      try {
        this.loading = true
        let { data = '' } = await getTemplate({
          id: 2
        })

        if (data) {
          const el = document.createElement("a");
          el.style.display = "none";
          el.setAttribute("target", "_blank");
          el.setAttribute("download", "");
          el.href = data;
          document.body.appendChild(el);
          el.click();
          document.body.removeChild(el);
        } else {
          this.$message.error('模板异常，请联系管理员')
        }
      } finally {
        this.loading = false
      }
    }
  },
  created() {
    if (this.$route.query.pagination) {
      let { pagination, searchForm } = this.$route.query
      if (searchForm) this.searchForm = searchForm
      if (pagination) this.pagination = pagination
    }
    this.parentRoleId = _getParentRoleId()

    this.getDriverList();
  },
};
</script>
<style lang="scss">
.bigDriverManage {
  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination {
    margin-top: 10px;
  }
}

.my-label {
  font-weight: bold !important;
}

.detail-dialog {
  .el-dialog {
    margin-top: 8vh !important;

    .el-dialog__body {
      padding: 10px !important;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .img {
      width: 280px;
      height: 180px;
    }

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }
  }
}
</style>
